import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Thiredscreen from "./thiredscreen";
import Firstscreen from "./Firstscreen";
import Secondscreen from "./Secondscreen";
const Hero = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Enable infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (2 seconds)
    fade: true, // Enable fade animation
    pauseOnHover: false,     // Do not pause when hovered
    arrows: false, // Disable left/right navigation arrows
    beforeChange: (current, next) => setActiveSlide(next), // Update the active slide
    appendDots: (dots) => (
      <ul className="mt-4"> {dots} </ul> // Custom tailwind for the dots container
    ),
  
  };
  return (
    <section id="Home" className="bg-secondary sm:mt-[5vw] lg:mt-[2vw] " >
      <Slider {...settings} className="text-[3vw]">
        <div
          className={`w-full h-full transition-opacity duration-500 ease-out ${
            activeSlide === 0 ? "animate-slideIn" : "animate-slideOut"
          }`}
        >
          <Firstscreen />
        </div>
        <div
          className={`w-full h-full transition-opacity duration-500 ease-out ${
            activeSlide === 1 ? "animate-slideIn" : "animate-slideOut"
          }`}
        >
          <Secondscreen />
        </div>
        <div
          className={`w-full h-full transition-opacity duration-500 ease-out ${
            activeSlide === 2 ? "animate-slideIn" : "animate-slideOut"
          }`}
        >
          <Thiredscreen />
        </div>
      </Slider>
    </section>
  );
};

export default Hero;
