import React from 'react'
import { HiLocationMarker } from "react-icons/hi";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { HiClock } from "react-icons/hi2";
import { AiFillYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
const Headerinfo = () => {
  const handleClick = (link) => {
    console.log('clicked')
    window.open(`${link}`, '');
  };
  return (
    <div className=' lg:flex lg:justify-center xl:flex xl:px-[8%] overflow-hidden hidden bg-white pt-[2%] py-[1%] '>
      <div className='flex items-center px-5'>
        <HiLocationMarker style={{color:'#17BBA0'}} size='1.5vw' onClick={()=>handleClick('https://www.linkedin.com/company/livmo-pvt-ltd/')}/>
        <h1 className='px-2 font-poppins text-text1 text-[1vw]'>Guindy , Chennai</h1>
      </div>
      <div className='flex items-center px-5'>
        <FaPhoneVolume style={{color:'#17BBA0'}} size='1.5vw'/>
        <h1 className='px-2 font-poppins text-text1 text-[1vw]'>+91 97890 86627</h1>
      </div>
      <div className='flex items-center px-5'>
        <IoMail style={{color:'#17BBA0'}} size='1.5vw'/>
        <h1 className='px-2 font-poppins text-text1 text-[1vw]'>info@westayclose.in</h1>
      </div>
      <div className='flex items-center px-5'>
        <HiClock style={{color:'#17BBA0'}} size='1.5vw'/>
        <h1 className='px-2 font-poppins text-text1  text-[1vw]'>Mon -Sat: 9.00am - 6.00pm</h1>
      </div>
      <div className='flex justify-between px-5 w-[14%]'>
        <button className=' w-7 h-7 rounded-[14px] bg-secondary flex justify-center items-center' onClick={()=>{handleClick('https://www.youtube.com/channel/UCSZI67yQPsacOFh4xLJxIXQ')}} >
        <AiFillYoutube style={{color:'#17BBA0'}} size='1.5vw' />
        </button>
        <div className=' w-7 h-7 rounded-[14px] bg-secondary flex justify-center items-center'>
        <FaInstagram style={{color:'#17BBA0'}} size='1.5vw'/>
        </div>
        <button className=' w-7 h-7 rounded-[14px] bg-secondary flex justify-center items-center'onClick={()=>handleClick('https://www.linkedin.com/company/livmo-pvt-ltd/')} >
        <FaLinkedin style={{color:'#17BBA0'}} size='1.5vw'/>
        </button>
        <div className=' w-7 h-7 rounded-[14px] bg-secondary flex justify-center items-center shadow-current'>
        <FaFacebook style={{color:'#17BBA0'}} size='1.5vw'/>
        </div>
      </div>
    </div>
  )
}

export default Headerinfo
