import React from "react";
import { BsTelephonePlusFill } from "react-icons/bs";
import { BiSolidBellPlus } from "react-icons/bi";
import { IoMdChatboxes } from "react-icons/io";
const Floating = () => {

  return (
    <div className=" fixed bottom-[8%] right-[4%] flex items-center group justify-center z-[40]">
        <div className="bg-gradient-to-br from-radialg1 to-radialg2 px-3 rounded-lg group-hover:flex group-hover:items-center group-hover:justify-center opacity-0 transform scale-75 transition-all duration-1000 ease-in-out group-hover:opacity-100 group-hover:scale-100">
          <h1 className="font-[600] font-poppins sm:text-[8px] md:text-[1vw] text-darkblue">
            WE ARE READY TO HELP YOU
          </h1>
        </div>
        <div className="lg:w-[4vw] lg:h-[4vw] sm:w-[10vw] sm:h-[10vw] hover:bg-bot rounded-full bg-bot flex justify-center items-center">
          <img
            src={require("../../assets/bots.png")}
            className="object-contain lg:w-[3vw] lg:h-[3vw] sm:w-[5vw] sm:h-[5vw]"
            alt="bot"
          />
        </div>

      <div className=" opacity-0 transform scale-75 transition-all duration-1000 ease-in-out group-hover:opacity-100 group-hover:scale-100 group-hover:block absolute sm:right-[8%] sm:bottom-[90%] lg:right-[3%] lg:bottom-[75%]">
        <div className="lg:w-[3vw] lg:h-[3vw] sm:w-[5vw] sm:h-[5vw] rounded-full bg-darkblue flex justify-center items-center mt-[2vw]">
          <BsTelephonePlusFill className="lg:w-[1.5vw] lg:h-[1.2vw] sm:w-[4vw] sm:h-[3vw] " color="white"/>
        </div>
        <div className="lg:w-[3vw] lg:h-[3vw] sm:w-[5vw] sm:h-[5vw] rounded-full bg-darkblue flex justify-center items-center my-[2vw]">
          <BiSolidBellPlus className="lg:w-[1.5vw] lg:h-[1.2vw] sm:w-[4vw] sm:h-[3vw] " color="white"/>
        </div>
        <div className="lg:w-[3vw] lg:h-[3vw] sm:w-[5vw] sm:h-[5vw] rounded-full bg-darkblue flex justify-center items-center mb-[2vw]">
          <IoMdChatboxes className="lg:w-[1.5vw] lg:h-[1.2vw] sm:w-[4vw] sm:h-[3vw] " color="white"/>
        </div>
      </div>
    </div>
  );
};

export default Floating;
