import React, { useState } from "react";
import "../../index.css";
import { FaCheckCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
const Plans = () => {
  const [open, setopen] = useState(false);
  const data = [
    { id: 1, item: " 24/7 Health care assistant" },
    { id: 2, item: " One home visit by field executive" },
    {
      id: 3,
      item: "  Complete blood count, urine routine, TSH , HBA1C, Random blood sugar, Renal function test,Liver function test, SerumElectrolyte, Serum VIT D , Urine PCR, Peripheral Smear",
    },
    { id: 4, item: "  One zoom session with general consultant (max 20 mins)" },
    {
      id: 5,
      item: " one zoom session with PREVENTIVE HEALTH PHYSICIAN SPECIALIST(20mins)",
    },
    { id: 6, item: " one nutritionist advice with diet chart for 1month " },
    {
      id: 7,
      item: " one home visit by Dental physician ( procedures not included)",
    },
    { id: 8, item: " one home visit for Ophthal screening" },
    { id: 9, item: " one wellness session with yoga (30 min)" },
    { id: 10, item: " one home visit by General doctor " },
    {
      id: 11,
      item: " one home visit by physiotherapist ( not including session)",
    },
  ];

  return (
    <section
      id="Plan"
      data-aos="zoom-in"
      data-aos-duration="300"
      className="bg-secondary mt-[6%] "
    >
      <div className="flex flex-col justify-center  items-center">
        <h1 className="md:text-[2.5vw] sm:text-[12px] font-[700] font-poppins">
          OUR <span className="text-primary">EXCLUSIVE</span> PLAN
        </h1>
        <p className="md:text-[1.2vw] sm:text-[8px] font-[300] font-poppins w-[60vw] text-center">
          Choose a Plan that Fits You Best
        </p>
      </div>
      <div className="w-[100vw] sm:h-[65vw] md:h-[55vw] mt-[3%] relative">
        <img
          src={require("../../assets/couple.jpeg")}
          className="h-[100%] absolute w-full object-cover"
          alt="happy couple"
        />
        <div className="absolute inset-0 bg-black top-0 opacity-65 "></div>
        <div className="absolute inset-0 bg-white top-0 opacity-20 "></div>
        <div className="md:w-[25%] md:h-[20vw] sm:w-[35%] sm:h-[22vw] md:hover:h-[53vw]  sm:hover:h-[60vw] hover:cursor-pointer group absolute top-[2%] left-[15%] z-10  bg-secondary rounded-[2vw] overflow-hidden">
          <div className="flex justify-between items-center ">
            <h1 className="md:text-[1.5vw] sm:text-[8px] font-[700] font-poppins pl-[4%]">
              PROXY PACK
            </h1>
            <div className="grid grid-cols-2 relative -z-10 gap-[1vw] pr-[3%] pt-[1%] transform transition-transform duration-300 group-hover:scale-[240%]  group-hover:-rotate-[70deg]">
              <div className="h-[3vw] w-[3vw] bg-primary rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="hidden group-hover:block absolute inset-0 bg-white opacity-45 "></div>
            </div>
          </div>
          <div className="flex items-center">
            <h1 className="text-[3vw] text-black font-[700] font-montserrat pl-[4%]">
              42$
            </h1>
            <h2 className="md:text-[1.2vw] sm:text-[7px] text-text1 font-[700] font-montserrat pl-[2%]">
              per month
            </h2>
          </div>
          <div className="h-[.25vw] bg-blue-500 w-[15%] ml-[4%]" />
          <div className="pl-[4%] mt-[2%]">
            <p className="md:text-[.75vw] sm:text-[6px] text-text1 font-[400] font-montserrat">
              ESSENTIAL HEALTH CARE
            </p>
            <div className=" flex justify-center items-center pt-[5%]">
              <button
                type="button"
                className=" w-[90%] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg  py-[1vw] text-center me-2 "
              >
                <p className="text-white md:text-[1vw] sm:text-[6px] font-[500] font-montserrat">
                  CHOOSE PLAN
                </p>
              </button>
            </div>
          </div>
          {/* <div className="pl-[2%] hidden group-hover:flex mx-auto mt-[2%]  justify-center items-center">
            <button
              type="button"
              className="  w-[85%] border border-black  rounded-lg  py-[1vw] text-center me-2 "
            >
              <p className="text-black text-[1vw] font-[700] font-montserrat">
                LEARN MORE
              </p>
            </button>
          </div> */}
          <div className="mx-[4%] pl-[2%] hidden sm:mt-[5%] md:mt-[14%] group-hover:block">
            <ul className="list-none  w-[90%] pl-[3%]">
              <li className="flex md:mt-[3%] sm:items-center w-[90%] ">
                <FaCheckCircle className="text-green-500 px-[1%]  md:w-[4vw] md:h-[2vw] sm:w-[4vw] sm:h-[3vw]" />
                <span className="font-montserrat font-[700] w-[100%]  md:text-[1vw] sm:text-[5px] text-text3 pl-[4%] ">
                  24/7 Health care assistant
                </span>
              </li>
              <li className="flex md:mt-[3%] sm:items-center w-[90%] ">
                <FaCheckCircle className="text-green-500 px-[1%] md:w-[4vw] md:h-[2vw] sm:w-[4vw] sm:h-[3vw]" />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[4%] ">
                  One home visit by field executive
                </span>
              </li>
              <li className="flex my-[3%] ">
                <FaCheckCircle className="text-green-500 px-[1%]md:w-[4vw] md:h-[2vw] sm:w-[4vw] sm:h-[3vw] " />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[2%] ">
                  Lab investigations
                </span>
              </li>
              <li className="flex my-[3%] ">
                <FaCheckCircle className="text-green-500 px-[1%]md:w-[4vw] md:h-[2vw] sm:w-[4vw] sm:h-[3vw] " />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[2%] ">
                  complete blood count, urine routine, TSH, HBA1C
                </span>
              </li>
              <li className="flex my-[3%] ">
                <FaCheckCircle className="text-green-500 px-[1%]md:w-[4vw] md:h-[2vw] sm:w-[4vw] sm:h-[3vw] " />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[2%] ">
                  One zoom session with general consultant (max 20 mins)
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="md:w-[25%] md:h-[20vw] sm:w-[35%] sm:h-[22vw] md:hover:h-[53vw] sm:hover:h-[60vw] hover:cursor-pointer group absolute top-[2%] left-[55%] z-10  bg-secondary rounded-[2vw] overflow-hidden">
          <div className="flex justify-between items-center ">
            <h1 className="md:text-[1.5vw] sm:text-[8px] font-[700] font-poppins pl-[4%]">
              ELITE 360 PACK
            </h1>
            <div className="grid grid-cols-2 relative -z-10 gap-[1vw] pr-[3%] pt-[1%] transform transition-transform duration-300 group-hover:scale-[240%]  group-hover:-rotate-[70deg]">
              <div className="h-[3vw] w-[3vw] bg-primary rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="h-[3vw] w-[3vw] bg-darkblue rounded-full"></div>
              <div className="hidden group-hover:block absolute inset-0 bg-white opacity-45 "></div>
            </div>
          </div>
          <div className="flex items-center">
            <h1 className="text-[3vw] text-black font-[700] font-montserrat pl-[4%]">
              199$
            </h1>
            <h2 className="sm:text-[7px] md:text-[1.2vw] text-text1 font-[700] font-montserrat pl-[2%]">
              per month
            </h2>
          </div>
          <div className="h-[.25vw] bg-blue-500 w-[15%] ml-[4%]" />
          <div className="pl-[4%] mt-[2%]">
            <p className="md:text-[.75vw] sm:text-[6px] text-text1 font-[400] font-montserrat">
              SPECIALIZED CARE
            </p>
            <div className=" flex justify-center items-center pt-[5%] ">
              <button
                type="button"
                className="  w-[90%] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg  py-[1vw] text-center me-2 "
              >
                <p className="text-white md:text-[1vw] sm:text-[6px] font-montserrat">
                  CHOOSE PLAN
                </p>
              </button>
            </div>
          </div>
          <div className="pl-[2%] hidden group-hover:flex mx-auto mt-[2%]  justify-center items-center">
            <button
              type="button"
              className=" w-[85%] sm:border-[.5px] lg:border border-black  rounded-[1vw]  py-[1vw] text-center me-2 "
              onClick={() => {
                setopen(!open);
              }}
            >
              <p className="text-black md:text-[1vw] sm:text-[5px] font-[700] font-montserrat">
                LEARN MORE
              </p>
            </button>
          </div>
          <div className="mx-[4%] pl-[2%] hidden  group-hover:flex flex-col">
            <ul className="list-none  w-[90%]  py-[4%] pl-[3%]">
              <li className="flex mt-[3%] w-[90%] ">
                <FaCheckCircle className="text-green-500 px-[1%]  w-[4vw] sm:h-[3vw] md:h-[2vw]" />
                <span className="font-montserrat font-[700] w-[100%]  md:text-[1vw] sm:text-[5px] text-text3 pl-[4%] ">
                  24/7 Health care assistant
                </span>
              </li>
              <li className="flex md:mt-[3%] sm:items-center w-[90%] ">
                <FaCheckCircle className="text-green-500 pl-[1%]  w-[4vw] sm:h-[3vw] md:h-[2vw]" />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[4%] ">
                  One home visit by field executive
                </span>
              </li>
              <li className="flex mt-[3%] w-[90%]">
                <FaCheckCircle className="text-green-500 pl-[1%] w-[4vw] sm:h-[3vw] md:h-[2vw]  " />
                <span className="font-montserrat font-[700] md:text-[1vw] sm:text-[5px] w-[100%] text-text3 pl-[2%] ">
                  Complete blood count, urine routine, TSH , HBA1C, Random blood
                  sugar, Renal function test,Liver function test, Serum
                  Electrolyte, Serum VIT D , Urine PCR, Peripheral Smear
                </span>
              </li>
              <li className="flex my-[3%] ">
                <FaCheckCircle className="text-green-500 px-[1%] w-[4vw] sm:h-[3vw] md:h-[2vw] " />
                <span className="font-montserrat font-[700] w-[100%] md:text-[1vw] sm:text-[5px] text-text3 pl-[2%] ">
                  One zoom session with general consultant (max 20 mins)
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-[15vw] lg:h-[12vw] md:h-[15vw] bg-dots p-4 absolute left-0 bottom-[0%]"></div>
      <div className="w-[15vw] lg:h-[12vw] md:h-[15vw] bg-dots p-4 absolute right-0 top-[14%]"></div>
      {open ? (
        <div data-aos="zoom-in"  data-aos-duration="300" className="absolute top-0 bottom-0 left-0 right-0 bg-black50 z-50 flex flex-col justify-center items-center">
          <div className="bg-bg1 p-[4%]  relative">
            <div className="flex items-center  ">
              <h1 className=" sm:text-[8px] md:text-[1.5vw] font-[700] font-poppins w-[25%]">
                ELITE 360 PACK
              </h1>
              <h1 className="md:text-[2vw] sm:text-[10px] text-black font-[700] w-[25%] font-montserrat ">
               --- 199$
              </h1>
              <button
                  className="pl-[45%] text-gray-400 w-[25%] hover:text-white"
                  onClick={()=>{setopen(false)}}
                >
                  <ImCross
                    className="hover:text-red-700 w-[2vw] h-[2vw]"
                    color="red"
                  />
                </button>
            </div>
            <ul className=" list-none px-[4%]">
              {data.map((i, index) => {
                return (
                  <li key={index} className="flex mt-[1%]">
                    <FaCheckCircle className="text-green-500 px-[1%] w-[4vw] h-[2vw] " />
                    <h1 className="md:text-[1.2vw] sm:text-[6px] w-[100%] font-[400] font-poppins text-text1 ">{i.item}</h1>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Plans;
