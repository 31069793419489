import React from "react";
import "../../index.css";
import StarRating from "./Starrating";
import Slider from "react-slick";
const Testimony = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Slide transition speed
    slidesToShow: 2, // Show 2 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (2 seconds)
    pauseOnHover: true, // Do not pause when hovered
    appendDots: (dots) => (
      <ul className="pt-[5%]"> {} </ul> // Custom tailwind for the dots container
    ),
  };
  const data = [
    {
      img: require("../../assets/linda.png"),
      para: "After my knee surgery, the convenience of online consultations made my recovery smoother than I could have imagined.",
      name: "Linda A.",
      rating: 4.1,
    },
    {
      img: require("../../assets/henry.png"),
      para: " WESTAYCLOSE has been a game-changer for our family. Their advocate has been a constant source of support and guidance, helping us navigate the complex healthcare system and ensuring that Grandma receives the best possible care. We can't thank them enough for their compassion, expertise, and dedication." ,
      name: "Ms. Aishwarya ",
      rating: 4.5,
    },
  ];
  const data1 = [
    {
      img: require("../../assets/joshua.png"),
      para: "The prescription refill system is a game-changer for managing my diabetes. Its really efficient and completely hassle-free.",
      name: "Joshua T.",
      rating: 4,
    },
    {
      img: require("../../assets/samantha.png"),
      para: "Finding a doctor who really understands all of my health needs has never been easier. This platform has changed my life.",
      name: "Samantha K",
      rating: 4.6,
    },
  ];
  const data2 = [
    {
      img: require("../../assets/samantha.png"),
      para: "I received outstanding care from WeStayClose. The staff was attentive and compassionate, making me feel valued and understood. Thanks to their expertise, I'm on the path to recovery and feeling better than ever!",
      name: "Kavya M.",
      rating: 4.6,
    },
    {
      img: require("../../assets/linda.png"),
      para: "The care I received from WeStayClose was exceptional. The team went above and beyond to ensure my comfort and recovery. I’m grateful for their professionalism and dedication to patient well-being!",
      name: "Lakshmanan R.",
      rating: 4.1,
    },
  ];
  const First = () => {
    return (
      <div className=" pl-[8%]">
        {data.map((data, index) => (
          <div className="flex items-center justify-center md:w-[80%] md:h-[15vw] sm:h-[25vw] sm:w-[100%] bg-gradient-to-br from-radialg1 to-radialg2 relative top-[3%] left-[2%] pb-[2%] pt-[2%] rounded-[1vw] mx-[1%] mt-[2%]">
            <div className="flex  justify-center">
              {/* <img
                src={data.img}
                className="w-[15%] h-[10%] rounded-[1vw]"
                alt={`${data.name}`}
              /> */}
              <div className="w-[90%] pl-[5%]">
                <p className="md:text-[1vw] sm:text-[6px] text-text1 font-[400] font-poppins pl-[2%]">
                  “{data.para}”
                </p>
                <div className="flex justify-between">
                  <h1 className="md:text-[1vw] sm:text-[6px] text-black font-[600] font-poppins">
                    - {data.name}
                  </h1>
                  <StarRating rating={data.rating} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const Second = () => {
    return (
      <div className=" pl-[8%]">
        {data1.map((data, index) => (
          <div className="flex items-center justify-center md:w-[80%] md:h-[15vw] sm:h-[25vw] sm:w-[100%] bg-gradient-to-br from-radialg1 to-radialg2 relative top-[3%] left-[2%] pb-[2%] pt-[2%] rounded-[1vw] mx-[1%] mt-[2%]">
            <div className="flex  justify-center">
              {/* <img
                src={data.img}
                className="w-[15%] h-[10%] rounded-[1vw]"
                alt={`${data.name}`}
              /> */}
              <div className="w-[90%] pl-[5%]">
                <p className="md:text-[1vw] sm:text-[6px] text-text1 font-[400] font-poppins pl-[2%]">
                  “{data.para}”
                </p>
                <div className="flex justify-between">
                  <h1 className="md:text-[1vw] sm:text-[6px] text-black font-[600] font-poppins">
                    - {data.name}
                  </h1>
                  <StarRating rating={data.rating} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const Third = () => {
    return (
      <div className=" pl-[8%]">
        {data2.map((data, index) => (
          <div className="flex items-center justify-center md:w-[80%] md:h-[15vw] sm:h-[25vw] sm:w-[100%] bg-gradient-to-br from-radialg1 to-radialg2 relative top-[3%] left-[2%] pb-[2%] pt-[2%] rounded-[1vw] mx-[1%] mt-[2%]">
            <div className="flex  justify-center">
              {/* <img
                src={data.img}
                className="w-[15%] h-[10%] rounded-[1vw]"
                alt={`${data.name}`}
              /> */}
              <div className="w-[90%] pl-[5%]">
                <p className="md:text-[1vw] sm:text-[6px] text-text1 font-[400] font-poppins pl-[2%]">
                  “{data.para}”
                </p>
                <div className="flex justify-between">
                  <h1 className="md:text-[1vw] sm:text-[6px] text-black font-[600] font-poppins">
                    - {data.name}
                  </h1>
                  <StarRating rating={data.rating} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <section id="testimony"  data-aos="fade-left"
    data-aos-duration="300" className="bg-secondary relative my-[2%]">
      <div className="flex flex-col justify-center  items-center">
        <h1 className="md:text-[2.5vw] sm:text-[12px] text-primary font-[700] font-poppins">
          Testimonials:
        </h1>
        <p className="md:text-[1.5vw] sm:text-[10px] text-black font-[700] font-poppins w-[60vw] text-center">
          Hear from Those We’ve Cared For
        </p>
        <p className="sm:text-[8px] md:text-[1.2vw] text-text1 font-[400] font-poppins w-[60vw] text-center">
          Discover the difference we make through the voices of those we’ve
          served:
        </p>
      </div>
      <div className="md:h-[35vw] sm:h-[60vw] w-full">
        <Slider {...settings} className="absolute z-10 sm:pl-[0%] md:pl-[5%] mt-[4%]">
          <div className="">
            <First />
          </div>
          <div className="">
            <Second />
          </div>
          <div className="">
            <Third />
          </div>
        </Slider>
      </div>
      <div className="w-[10vw] h-[10vw] bg-dots1 p-4  absolute sm:left-[2%] sm:top-[20%] md:left-[4%] md:top-[14%] rounded-full"></div>
      <div className="w-[10vw] h-[10vw] bg-dots1 p-4 absolute sm:bottom-0 sm:right-[1%] md:bottom-[0%] md:right-[4%] rounded-full"></div>
    </section>
  );
};

export default Testimony;
