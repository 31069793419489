import React, { useState, useEffect } from "react";
import { IoMdMenu } from "react-icons/io";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowForward } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";
import { ImCross } from "react-icons/im";
const Navbar = () => {
  const [activeSection, setActiveSection] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNestedDropdownOpen, setIsNestedDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleScroll = (event, sectionId) => {
    event.preventDefault(); // Prevent default anchor click behavior
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Scroll the section to the center of the viewport
      });
    }
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen); // Toggle drawer open/close
    setIsDropdownOpen(false);
    setIsNestedDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setIsNestedDropdownOpen(false);
  };
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleNestedDropdown = () => {
    setIsNestedDropdownOpen(!isNestedDropdownOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 200) {
          // Adjust the offset as needed
          currentSection = section.getAttribute("id");
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className=" sticky  lg:px-[%] sm:px-[1%] bg-bg1 top-0 z-[70] mt-[0%] shadow-lg shadow-gray-400">
      <div className="sm:flex lg:items-center lg:justify-normal w-full sm:flex-row sm:justify-between sm:items-center sm:px-[5%] md:pl-0 sm:py-[1%] md:pt-0">
        <img
          src={require("../../assets/logo3.png")}
          className=" max-w-full md:h-[80px] h-[50px] lg:pl-[5%] xl:pl-[10%]"
          alt="logo"
        />
        <div className="hidden lg:block pl-[18%]">
          <ul className="flex gap-7 py-3">
            <li>
              <a
                href={"#Home"}
                className={`${
                  activeSection === "Home"
                    ? "text-darkblue border-b-2 border-darkblue text-[1.2vw] font-[400] font-poppins"
                    : "text-text1 text-[1.2vw] border-b-2 border-transparent font-[500] font-poppins"
                }  hover:text-darkblue hover:border-darkblue`}
              >
                HOME
              </a>
            </li>
            <li>
              <a
                href={"#About"}
                className={` hover:text-darkblue hover:border-darkblue ${
                  activeSection === "About"
                    ? "text-darkblue border-b-2 border-darkblue text-[1.2vw] font-[400] font-poppins"
                    : "text-text1 border-b-2 border-transparent text-[1.2vw] font-[500] font-poppins"
                } `}
              >
                ABOUT US
              </a>
            </li>
            {/* <li className="relative group">
              <button className=" text-text1 text-[1.2vw] font-[400] font-poppins border-b-2 border-transparent hover:text-darkblue hover:border-darkblue focus:outline-none">
                <div className="flex items-center">
                  <h1 className="mr-1">PAGES</h1>
                  <IoIosArrowDown size={"1.2vw"} style={{ color: "black" }} />
                </div>
              </button>
              <ul className="absolute bg-white text-text1 rounded shadow-lg w-48 hidden group-hover:block">
                <li className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.2vw] font-[500] font-poppins">
                  <a href="#About">ABOUT</a>
                </li>
                <li className="relative">
                  <button
                    onClick={toggleNestedDropdown}
                    className="w-full  px-4 py-2 border-b-2 border-transparent hover:border-darkblue "
                  >
                    <div className="flex items-center justify-between  group">
                      <h1 className=" text-text1 text-[1.2vw] font-[500] hover:text-darkblue font-poppins">
                        OUR DOCTORS
                      </h1>
                      <IoIosArrowForward
                        size={"1.2vw"}
                        style={{ color: "black" }}
                      />
                    </div>
                  </button>

                  {isNestedDropdownOpen && (
                    <ul className="absolute left-full top-0 bg-white text-text1 mt-0 ml-2 rounded shadow-lg w-48">
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1vw] font-[500] font-poppins"
                      >
                        <a href="#ui-ux">OUR DOCTORS</a>
                      </li>
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1vw] font-[500] font-poppins"
                      >
                        <a href="#responsive">DOCTORS DESIGN</a>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.2vw] font-[500] font-poppins">
                  <a href="#marketing">TESTIMONIALS</a>
                </li>
                <li className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.2vw] font-[500] font-poppins">
                  <a href="#marketing">PRICING</a>
                </li>
                <li className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.2vw] font-[500] font-poppins">
                  <a href="#marketing">FAQS</a>
                </li>
                <li className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.2vw] font-[500] font-poppins">
                  <a href="#marketing">APPOINTMENT</a>
                </li>
              </ul>
            </li> */}

            <li className="relative">
            <a
                href={"#Plan"}
                className={`${
                  activeSection === "Plan"
                    ? "text-darkblue border-b-2 border-darkblue text-[1.2vw] font-[400] font-poppins"
                    : "text-text1 text-[1.2vw] border-b-2 border-transparent font-[500] font-poppins"
                }  hover:text-darkblue hover:border-darkblue`}
              >
                SERVICES
              </a>
            </li>
            {/* <li>
              <a
                href={"#Blog"}
                className={`${
                  activeSection === "Blog"
                    ? "text-darkblue border-b-2 border-darkblue text-[1.2vw] font-[400] font-poppins"
                    : "text-text1 border-b-2 border-transparent text-[1.2vw] font-[500] font-poppins"
                }  hover:text-darkblue hover:border-darkblue`}
              >
                BLOG
              </a>
            </li> */}
            <li>
              <a
                href={"#Contact"}
                className={`${
                  activeSection === "Contact"
                    ? "text-darkblue border-b-2 border-darkblue text-[1.2vw] font-[400] font-poppins"
                    : "text-text1 text-[1.2vw] border-b-2 border-transparent font-[500] font-poppins"
                }  hover:text-darkblue hover:border-darkblue`}
              >
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
        <div className=" lg:hidden ">
          <button
            onClick={toggleDrawer}
            className=" w-full shadow-sm bg-secondary  text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <IoMdMenu className="scale-150" size={"4vw"} />
          </button>
          <div
            className={`fixed top-0 right-0 z-[70] h-full sm:w-[40%]  bg-white text-text1 transition-transform transform ${
              isOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="p-4">
              <div className="flex justify-start">
                <img
                  src={require("../../assets/logo3.png")}
                  className=" max-w-full md:h-[50px] sm:h-[35px]"
                  alt="logo"
                />
                <button
                  className="pl-[10%] text-gray-400 hover:text-white"
                  onClick={toggleDrawer}
                >
                  <ImCross
                    className="hover:text-red-700 w-[2vw] h-[2vw]"
                    color="red"
                  />
                </button>
              </div>
              <ul className="mt-4 space-y-2 pl-[4%]">
                <li>
                  <a
                    href="#Home"
                    onClick={(e)=>{
                      toggleDrawer()
                    }}
                    className="text-[2vw] text-text1 font-[700] font-poppins border-b-2 border-transparent hover:border-darkblue hover:text-darkblue"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#About"
                    onClick={(e)=>{handleScroll(e,'About')
                      toggleDrawer()
                    }}
                    className="text-[2vw] text-text1 font-[700] font-poppins border-b-2 border-transparent hover:border-darkblue hover:text-darkblue"
                  >
                    About
                  </a>
                </li>
                {/* <li className="relative z-[70]">
                  <button
                    onClick={toggleDropdown}
                    className={`border-b-2 border-transparent
                 hover:text-darkblue hover:border-darkblue focus:outline-none text-text1 text-[1.2vw] font-[500] font-poppin
                 `}
                  >
                    <div className="flex items-center">
                      <h1
                        className={`text-[2vw]  font-[700] font-poppins border-b-2 border-transparent  ${
                          isDropdownOpen === true
                            ? "text-darkblue border-darkblue"
                            : ""
                        } text-text1`}
                      >
                        PAGES
                      </h1>
                      {isDropdownOpen ? (
                        <IoIosArrowUp
                          size={"3vw"}
                          style={{ color: "black", paddingLeft: "3%" }}
                        />
                      ) : (
                        <IoIosArrowDown
                          size={"3vw"}
                          style={{ color: "black", paddingLeft: "3%" }}
                        />
                      )}
                    </div>
                  </button>
                  {isDropdownOpen && (
                    <ul className="absolute right-full top-0  z-[70] bg-white text-text1 mt-2 rounded shadow-lg w-[75%]">
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue
                   hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                      >
                        <a href="#About">ABOUT</a>
                      </li>
                      <li className="relative">
                        <button
                          onClick={toggleNestedDropdown}
                          className="w-full text-left px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                        >
                          <div className="flex items-center justify-between">
                            <h1 className="mr-1">OUR DOCTORS</h1>
                            <IoIosArrowForward
                              size={"1.2vw"}
                              style={{ color: "black" }}
                            />
                          </div>
                        </button>
                        {isNestedDropdownOpen && (
                          <ul className="absolute right-full top-0 z-[70] bg-white text-text1 mt-0 ml-2 rounded shadow-lg w-[100%]">
                            <li
                              onClick={closeDropdown}
                              className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                            >
                              <a href="#ui-ux">OUR DOCTORS</a>
                            </li>
                            <li
                              onClick={closeDropdown}
                              className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                            >
                              <a href="#responsive">DOCTORS DESIGN</a>
                            </li>
                          </ul>
                        )}
                      </li>

                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                      >
                        <a href="#testimony">TESTIMONIALS</a>
                      </li>
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                      >
                        <a href="#plan">PRICING</a>
                      </li>
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                      >
                        <a href="#marketing">FAQS</a>
                      </li>
                      <li
                        onClick={closeDropdown}
                        className="px-4 py-2 border-b-2 border-transparent hover:text-darkblue hover:border-darkblue text-text1 text-[1.5vw] font-[500] font-poppins"
                      >
                        <a href="#marketing">APPOINTMENT</a>
                      </li>
                    </ul>
                  )}
                </li> */}
                <li>
                  <a
                    href="#Plan"
                    onClick={(e)=>{handleScroll(e,'Plan')
                      toggleDrawer()
                    }}
                    className="text-[2vw] text-text1 font-[700] font-poppins border-b-2 border-transparent hover:border-darkblue hover:text-darkblue"
                  >
                    Service
                  </a>
                </li>
                <li>
                  <a
                    href="#Contact"
                    onClick={(e)=>{handleScroll(e,'Contact')
                      toggleDrawer()
                    }}
                    className="text-[2vw] text-text1 font-[700] font-poppins border-b-2 border-transparent hover:border-darkblue hover:text-darkblue"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* Close Button inside the Drawer */}
          </div>

          {/* Overlay to close drawer on clicking outside */}
          {isOpen && (
            <div
              className="fixed inset-0 z-[52] bg-black opacity-20"
              onClick={toggleDrawer}
            ></div>
          )}
        </div>

        <div className=" ml-[5%] xl:ml-[8%] hidden lg:block">
          <a
          href='#Plan'
            type="button"
            className=" text-white text-[1vw] bg-gradient-to-r from-primary via-primary to-gradient2  font-medium rounded-lg px-[1vw] py-[.5vw] text-center me-2 mb-2"
          >
            Get started
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
