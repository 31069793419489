import React from "react";
import { FaYoutube } from "react-icons/fa";
import { IoMdVideocam } from "react-icons/io";
import { FaBlogger } from "react-icons/fa6";
import Slider from "react-slick";
const SocialMedia = () => {
  const handleClick = (link) => {
    console.log('clicked')
    window.open(`${link}`, '');
  };
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Slide transition speed
    slidesToShow: 3, // Show 2 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (2 seconds)
    pauseOnHover: true, // Do not pause when hovered
    appendDots: (dots) => (
      <ul className="pt-[5%]"> {} </ul> // Custom tailwind for the dots container
    ),
  };
  const First = () => {
    return (
      <div className="relative w-[85%] ml-[2%] hover:scale-110" onClick={()=>{handleClick('https://www.youtube.com/channel/UCSZI67yQPsacOFh4xLJxIXQ')}}>
        <div className="absolute inset-0 bg-gradient-to-b from-text1 to-black opacity-10 "></div>
        <img
          src={require("../../assets/youtube.jpeg")}
          className="w-[90vw] h-[15vw] rounded-[1vw]"
          alt="youtube"
        />

        <div className="flex items-center absolute bottom-[0%] left-[5%] bg-transparent">
          <FaYoutube style={{ color: "red" }} size="2vw" />
          <p className="pl-1 font-roboto text-[1vw] text-white font-[400]">
            <span className="text-tag">#westayclose </span>: We are
            providing Emergency...
          </p>
        </div>
        <IoMdVideocam
          size="2vw"
          style={{ color: "white" }}
          className="absolute top-[1%] right-[2%]"
        />
      </div>
    );
  };
  const Second = () => {
    return (
      <div className="relative w-[85%] ml-[2%] hover:scale-110"  >
        <div className="absolute inset-0 bg-gradient-to-b from-text1 to-black opacity-[.35] "></div>
        <img
          src={require("../../assets/gparent.jpeg")}
          className="w-[90vw] h-[15vw]  rounded-[1vw]"
          alt="youtube"
        />
        <div className="flex items-center absolute bottom-[0%] left-[5%] bg-transparent">
          <FaBlogger style={{ color: "orange" }} size="2vw" />

          <p className="pl-1 font-roboto text-[1vw] text-white font-[400]">
            Embed Google Reviews on any Website...
          </p>
        </div>
      </div>
    );
  };
  const Third = () => {
    return (
      <div className="relative w-[85%] ml-[2%] hover:scale-110">
        <div className="absolute inset-0 bg-gradient-to-b from-text1 to-black opacity-10 "></div>
        <img
          src={require("../../assets/electric.jpg")}
          className="w-[80vw] h-[15vw]  rounded-[1vw]"
          alt="youtube"
        />
        <div className="flex items-center absolute bottom-[0%] left-[1%]">
          <img
            src={require("../../assets/insta.png")}
            className="w-[10%]"
            alt="instagram"
          />

          <p className="pl-[1%] font-roboto text-[1vw] text-white font-[400]">
            Instagram FeedLink
          </p>
          <div className="mt-[2%] ml-[12%] ">
            <button
              type="button"
              className="  w-[8vw] bg-gradient-to-r from-primary via-primary to-gradient2  rounded-lg py-1 text-center mb-2"
            >
              <p className="text-white text-[1vw] font-poppins">Visit Us</p>
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <section id="Contact" className="bg-secondary ">
      <div className="flex flex-col justify-center  items-center">
        <h1 className="text-[2.5vw] font-[700] font-poppins">
          Our <span className="text-primary">Social media</span> Updates
        </h1>
        <p className="text-[1.2vw] font-[300] font-poppins w-[60vw] text-center">
          We are providing  health care services to ensure
          seamless and comprehensive care for our Beneficiaries.
        </p>
      </div>
      <div className="w-[100%] ">
        <Slider {...settings} className="ml-[4%] my-[5%]">
          <div>
            <First />
          </div>
          <div>
            <Second />
          </div>
          <div>
            <Third />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default SocialMedia;
