import React from "react";
import "../../index.css";
const Aboutus = () => {
  return (
    <section id="About" data-aos="fade-up"  data-aos-duration="1000" className={`bg-secondary md:mt-[5%] sm:mt-[8%] sm:h-[70vw] md:h-[55vw] mb-[2%] `}>
      <div className="flex flex-col items-center ">
        <div className="flex">
          <h1 className="md:text-[3vw] sm:text-[15px] font-[700] text-primary pr-[1vw] font-poppins">
            About{" "}
          </h1>
          <h1 className="md:text-[3vw] sm:text-[15px] font-[700] font-poppins"> Us</h1>
        </div>
        <p className="md:text-[1.3vw] sm:text-[8px] text-text1">
          Our Journey to Connecting Hearts
        </p>
      </div>
      <div className="flex relative  lg:h-[100vh] md:h-[80vh] sm:h-[80vw]  ">
        <div className="w-[50vw] h-[65vw]  ">
          <img
            src={require("../../assets/vector.png")}
            className="lg:h-[50vw] lg:w-[36vw] md:h-[50vw] md:w-[36vw] w-[25vw] object-contain absolute md:top-[3%] sm:top-[6%] sm:left-[6%] md:left-[8%] sm:h-[50vw] sm:w-[38vw] "
            alt="vector 9"
          />
          <img
            src={require("../../assets/croppic.png")}
            className="md:h-[50vw] md:w-[50vw] sm:h-[60vw] sm:w-[50vw] object-cover absolute z-[10] top-0 left-[2%]"
            alt="caring nurse"
          />
        </div>
        <div className="w-[40%] mt-[4%]">
          {/*right container */}
          <h1 className="md:text-[3vw] sm:text-[14px] text-darkblue font-[700] font-poppins">
            The Best Choice For Your Health!
          </h1>
          <p className=" text-text1 md:text-[1vw] font-poppins sm:text-[8px]">
            we want to make healthcare easy for families, especially when
            they're apart. We know it can be tough to take care of loved ones
            from a distance, so we're here to help.
          </p>
          <p className="mt-[1vw] text-text1 md:text-[1vw] font-poppins sm:text-[8px]">
            Our goal is to make things less stressful for family members,
            especially those looking after elderly relatives. We want to give
            them peace of mind and make sure their loved ones get the care they
            need!
          </p>
          <div className="mt-[2%] ">
            <button
              type="button"
              className="  w-[75%] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg sm:py-[2%]  md:py-[1.3vw]  me-2 mb-2"
            >
              <p className="text-white md:text-[1.3vw] sm:text-[8px] font-poppins">
                EXPLORE MORE
              </p>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
