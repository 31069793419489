import React, { useState } from "react";
import { IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { GoHomeFill } from "react-icons/go";
import { TiTick } from "react-icons/ti";
import "../../index.css";
const Platform = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [moberror, setmobError] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [adderror, setadderror] = useState("");
  // Function to validate email
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      setError(""); // No error if email is valid
    } else {
      setError("Please enter a valid email address.");
    }
  };
  const validateMobile = (value) => {
    const mobileRegex = /^[6-9]\d{9}$/; // Indian mobile number validation
    if (mobileRegex.test(value)) {
      setmobError(""); // No error if mobile number is valid
    } else {
      setmobError("Please enter a valid 10-digit Indian mobile number.");
    }
  };
  const validateLength = (value) => {
    if (value.length < 10) {
      setadderror("Address must be at least 10 characters long.");
    } else {
      setadderror(""); // Clear the error if valid
    }
  };
  const handlemobile = (e) => {
    const inputValue = e.target.value;
    // Allow only numbers
    if (/^[0-9\b]+$/.test(inputValue) || inputValue === "") {
      setphone(inputValue); // Update state only if input is valid (numbers or empty for deletion)
      validateMobile(inputValue);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };
  const handleadd = (e) => {
    setaddress(e.target.value);
    validateLength(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!error && email) {
      alert("Email is valid!");
    }
  };

  const data = [
    {
      id: 1,
      title: "Create Account",
      para: "Sign up and fill in your details securely. Setting up your profile this way would ensure that you stay up-to-date with your medical processes.",
    },
    {
      id: 2,
      title: "Select Your Service",
      para: "Select from our range of services and book a consultation. Booking a consultation with Westayclose is fairly simple and straight-forward.",
    },
    {
      id: 3,
      title: "Make Payment",
      para: '"Online payment services allow users to conveniently transfer funds through digital platforms, offering secure, fast, and efficient transactions.',
    },
  ];
  return (
    <div data-aos="zoom-in" data-aos-duration="300" className="bg-secondary relative md:h-[55vw] sm:h-[120vw] mt-[4%] ">
      <div className="flex flex-col justify-center  items-center">
        <h1 className="md:text-[2.5vw] sm:text-[13px] font-[700] font-poppins">
          How <span className="text-primary">Our Platform</span> Works
        </h1>
        <p className="md:text-[1.2vw] sm:text-[8px] font-[300] font-poppins w-[60vw] text-center">
          Navigating your healthcare journey with We stay close is seamless.
          Just follow these steps mentioned below to proceed with your selected
          services:
        </p>
      </div>
      <div className="bg-secondary flex mt-[5%] flex-wrap justify-center">
        {data.map((data, i) => (
          <div
            key={i}
            className=" md:w-[30%] sm:w-[45%] bg-gradient-to-br from-radialg1 to-radialg2 relative top-[3%] left-[2%] pb-[2%] pt-[1%] sm:mt-[2%] rounded-[1vw] mx-[1%]"
          >
            <div className="md:w-[3vw] md:h-[3vw] sm:w-[25px] sm:h-[25px] rounded-full bg-primary  border-4 border-white flex justify-center items-center m-[1%] absolute top-[-10%] left-[-3%] z-20">
              <p className="text-white md:text-[2vw] sm:text-[8px] font-poppins font-[700]">
                {data.id}
              </p>
            </div>
            <div className="flex flex-col items-center  pb-[2%]">
              <h1 className="md:text-[1.5vw] sm:text-[12px] font-poppins font-[700] text-cardtex">
                {data.title}
              </h1>
              <p className="md:text-[1.1vw] sm:text-[8px] font-poppins font-[300] w-[75%] text-text1">
                {data.para}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="relative lg:h-[20vw] sm:h-[10vw]">
      <form
        onSubmit={handleSubmit}
        className="w-[85%] bg-gradient-to-br from-radialg1 to-radialg2 mx-auto mt-[5%] py-[3%] absolute left-[8%] top-[5%] z-20"
      >
        <h1 className=" font-[700] text-[2vw] text-black1 font-manrope px-[6%] py-[1%]">
          Easily care your loved once in 3 simple steps.
        </h1>
        <div className="flex  pl-[6%] ">
          <div className="mb-4 w-[25%] mt-[2%] ">
            <label htmlFor="email" className="block md:mb-2">
              <div className="flex items-center">
                <IoMail className=" md:text-[1.8vw] sm:text-[7px] text-primary" />
                <h2 className="pl-[3%] font-poppins font-[600] md:text-[1.2vw] sm:text-[6px] text-text1">
                  Email Address
                </h2>
              </div>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleChange}
              className={`shadow w-[80%] h-[50%] md:h-[40%] text-[1vw] mt-[2%] border rounded-[0.5vw] sm:py-[.5%] md:py-[1% ] p-[2%] placeholder:text-[1vw] placeholder-shown:py-[8%] text-gray-700 focus:outline-none focus:shadow-outline ${
                error ? "border-red-500" : "border-primary"
              }`}
              placeholder="Your Email Address"
            />
            {error && <p className="text-red-500 text-[1vw] mt-2">{error}</p>}
          </div>
          <div className="mb-4 w-[25%]  mt-[2%]">
            <label htmlFor="contact" className="block md:mb-2">
              <div className="flex items-center">
                <FaPhoneAlt className=" text-[1.8vw] text-primary" />
                <h2 className="pl-[3%] font-poppins font-[600] md:text-[1.2vw] sm:text-[6px] text-text1">
                  Contact Number
                </h2>
              </div>
            </label>
            <input
              type="phone"
              id="contact"
              value={phone}
              onChange={handlemobile}
              className={`shadow w-[80%] h-[50%] md:h-[40%] text-[1vw] mt-[2%] border rounded-[0.5vw] sm:py-[.5%] md:py-[1% ] p-[2%] placeholder:text-[1vw] placeholder-shown:py-[8%] text-gray-700 focus:outline-none focus:shadow-outline ${
                error ? "border-red-500" : "border-primary"
              }`}
              placeholder="Enter Your Contact Number"
            />
            {moberror && (
              <p className="text-red-500 text-[1vw] mt-2">{moberror}</p>
            )}
          </div>
          <div className="mb-4 w-[25%]  mt-[2%]">
            <label htmlFor="address" className="block md:mb-2">
              <div className="flex items-center">
                <GoHomeFill className=" text-[1.8vw] text-primary" />
                <h2 className="pl-[3%] font-poppins font-[600] md:text-[1.2vw] sm:text-[6px] text-text1">
                  Residential Address
                </h2>
              </div>
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={handleadd}
              className={`shadow w-[80%] h-[50%] md:h-[40%] text-[1vw] mt-[2%] border rounded-[0.5vw] sm:py-[.5%] md:py-[1% ] p-[2%] placeholder:text-[1vw] placeholder-shown:py-[4%] text-gray-700 focus:outline-none focus:shadow-outline ${
                error ? "border-red-500" : "border-primary"
              }`}
              placeholder="Enter Your Address"
              
            />
            {adderror && (
              <p className="text-red-500 text-[1vw] mt-2">{adderror}</p>
            )}
          </div>
          <div className="w-[25%]  mt-[4%]">
            <div className="mt-[4%] ">
              <button
                type="button"
                className="w-[12vw] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg md:py-[1%] sm:py-[2%] text-center me-2 mb-2"
              >
                <div className="flex justify-center px-[3%] items-center ">
                  <a href="#Plan" className="text-white text-[1.3vw] font-poppins">
                    Select Plan
                  </a>
                  <div className=" w-[20%] h-[20%] rounded-full ml-[2%] bg-white flex items-center justify-center">
                    <TiTick className=" text-primary text-[1.3vw]" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
     
      </div>
      <div className="w-[15vw] h-[15vw]  bg-dots p-4 absolute md:left-0 md:bottom-0 lg:left-0 lg:bottom-[4%]"></div>
    </div>
  );
};

export default Platform;
