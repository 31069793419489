import React from 'react'
import { PiYoutubeLogoThin } from "react-icons/pi";
import { FaTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate=useNavigate()
  return (
    <footer>
      <div className="bg-bggreen pb-[1%] mt-[2%]">
      <div className="flex items-center justify-around">
        <div className="w-[20%] ml-[6%] mr-[12%]">
          <img src={require("../../assets/logo.png")} className="w-[100%]" alt="logo"/>
          <p className="text-[1.2vw] font-[400] font-poppins text-text1">
            Stay relax we care for your loved once wherever you are.
          </p>
        </div>
        <div className="w-[20%]">
          <ul className=" list-none">
            <li className="md:text-[1vw] sm:text-[5px] font-[400] font-poppins text-darkblue py-[2%]">
              Support
            </li>
            {/* <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Getting Started
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              FAQ
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Help Articles
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Report an issue
            </li> */}
            <li className="md:text-[1vw] sm:text-[5px] hover:cursor-pointer font-[400] text-text1 font-poppins py-[2%]" onClick={()=>{navigate('/contact')}}>
              Contact Help Desk
            </li>
          </ul>
        </div>
        {/* <div className="w-[20%]">
          <ul className=" list-none">
            <li className="md:text-[1vw] sm:text-[5px] font-[400] font-poppins text-darkblue py-[2%]">
              Services
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Booking appointments
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Online consultations
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Prescriptions
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Medicine Refills
            </li>
            <li className="text-[1vw] font-[400] text-text1 font-poppins py-[2%]">
              Medical Notes
            </li>
          </ul>
        </div> */}
        {/* <div className="w-[20%]">
          <ul className=" list-none">
            <li className="md:text-[1vw] sm:text-[5px] font-[400] font-poppins text-darkblue py-[2%]">
              Legal
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Terms & Conditions
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Privacy Policy
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Cookie Notice
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Cookie Preferences
            </li>
            <li className="md:text-[1vw] sm:text-[5px] font-[400] text-text1 font-poppins py-[2%]">
              Trust Center
            </li>
          </ul>
        </div> */}
      </div>
      <div className="flex mt-[2%]">
      <div className='flex justify-between px-[6%] w-[25%] ml-[5%] mr-[10%]'>
        <div className=' w-[2vw] h-[2vw] rounded-[14px] bg-primary1 flex justify-center items-center'>
        <PiYoutubeLogoThin style={{color:'black'}} size='1.5vw'/>
        </div>
        <div className=' w-[2vw] h-[2vw] rounded-[14px] bg-primary1 flex justify-center items-center'>
        <FaTwitter style={{}} size='1.5vw'/>
        </div>
        <div className=' w-[2vw] h-[2vw] rounded-[14px] bg-primary1 flex justify-center items-center shadow-current'>
        <FaInstagram style={{}} size='1.5vw'/>
        </div>
        <div className=' w-[2vw] h-[2vw] rounded-[14px] bg-primary1 flex justify-center items-center'>
        <FaLinkedin style={{}} size='1.5vw'/>
        </div>
       
      </div>
      <div className="pl-[25%]">
        <p className="text-[1.2vw] text-text1 font-[600] font-poppins">Westayclose 2024 © All Rights Reserved</p>
      </div>
      </div>
      </div>
    </footer>
  )
}

export default Footer
