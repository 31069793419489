import React, { useEffect } from "react";
import { ImPlus } from "react-icons/im";
import { FaPercentage } from "react-icons/fa";
import AOS from "aos";
import "../../index.css";
import "aos/dist/aos.css";
import CountUp from "react-countup";

const Ourfocus = () => {
  useEffect(() => {
    AOS.init({ duration: 3000, delay: 100, once: true });
    AOS.refresh(); // Ensures AOS recalculates positions after any DOM updates
  }, []);
  return (
    <div
      data-aos="fade-right"
      data-aos-duration="300"
      className={`bg-secondary  `}
    >
      <div className="flex flex-col justify-center  items-center">
        <h1 className="md:text-[2.5vw] sm:text-[12px] font-[700] font-poppins">
          Our{" "}
          <span className="text-primary font-[700] font-poppins">Focus</span> On
        </h1>
        <p className="sm:text-[8px] md:text-[1.2vw] font-[300] font-poppins w-[60vw] text-center">
          We are providing  health care services to ensure seamless and
          comprehensive care for our Beneficiaries.
        </p>
      </div>
      <div className="flex items-center justify-center my-[4%]">
        <div className="bg-white hover:bg-primary group md:w-[25%] sm:w-[30%] sm:h-[40vw] md:h-[27vw] py-[4%] rounded-[2vw] mx-[2%] hover:scale-[115%] transition-transform duration-100 ease-in-out">
          <div className="flex flex-col items-center justify-center">
            <img
              src={require("../../assets/doctor.png")}
              alt="doctor"
              className="w-[50%] h-[60%] scale-75"
            />
            <h1 className="md:text-[1.5vw] sm:text-[8px] text-black group-hover:text-white font-[700] font-poppins">
              Timely Service
            </h1>
          </div>
          <p className="sm:text-[7px] md:text-[1vw] pt-[5%] font-[400] group-hover:text-white font-poppins text-text1 w-[85%] pl-[5%] text-left mx-auto">
            Emergency coordinated care: swift, seamless support, prioritizing
            well-being.
          </p>
        </div>
        <div className="bg-white  hover:bg-primary group md:w-[25%] sm:w-[30%] sm:h-[40vw] md:h-[27vw] py-[5%] rounded-[2vw] mx-[2%]  hover:scale-[115%] transition-transform duration-100 ease-in-out">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[40%]  rounded-full bg-white flex justify-center items-center">
              <img
                src={require("../../assets/medicine.png")}
                alt="doctor"
                className="w-[100%] h-[60%] scale-50"
              />
            </div>
            <h1 className="sm:text-[8px] md:text-[1.5vw] text-black group-hover:text-white font-[700] font-poppins pt-[7%]">
              On-Demand Assistance
            </h1>
          </div>
          <p className="sm:text-[7px] md:text-[1vw] mt-[2%] pt-[2%] font-[400] font-poppins text-text1 group-hover:text-white w-[85%] pl-[5%] text-left mx-auto">
            Instant support whenever you need it, right at your fingertips with
            our on-demand assistance
          </p>
        </div>
        <div className="bg-white hover:bg-primary group md:w-[25%] sm:w-[30%] sm:h-[40vw] md:h-[27vw] py-[4%] rounded-[2vw] mx-[2%]  hover:scale-[115%] transition-transform duration-100 ease-in-out">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[40%] rounded-full bg-white flex justify-center items-center">
              <img
                src={require("../../assets/hospital.png")}
                alt="doctor"
                className="w-[100%] h-[60%] scale-50 border-4"
              />
            </div>
            <h1 className="sm:text-[8px] md:text-[1.5vw] text-black group-hover:text-white font-[700] font-poppins pt-[9%]">
              Dedicated Support
            </h1>
          </div>
          <p className="sm:text-[7px] md:text-[1vw] mt-[2%] pt-[2%] font-[400] font-poppins group-hover:text-white text-text1 w-[85%] pl-[5%] text-left mx-auto">
            Dedicated support: committed, compassionate, ensuring your
            well-being.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center relative h-[20vw] mt-[2%]">
        <div className=" flex justify-center items-center absolute inset-1 z-20">
          <div className="mx-[4%] flex flex-col justify-center items-center">
            <div className="flex items-center">
              <h1 className="text-[4vw] text-darkblue font-[600] font-poppins pr-[.5vw] ">
                <CountUp end={10} enableScrollSpy scrollSpyOnce={true} />
              </h1>
              <ImPlus className="text-primary text-[1.8vw] " />
            </div>
            <h1 className="text-[1.2vw] text-text2 font-[600] font-poppins">
              HEALTH CARE EXPERIENCE
            </h1>
          </div>
          <div className="mx-[4%] flex flex-col justify-center items-center">
            <div className="flex items-center">
              <h1 className="text-[4vw] text-darkblue font-[600] font-poppins pr-[.5vw] ">
                <CountUp end={100} enableScrollSpy scrollSpyOnce={true} />
              </h1>
              <FaPercentage className="text-primary text-[3vw] " />
            </div>
            <h1 className="text-[1.2vw] text-text2 font-[600] font-poppins">
              CUSTOMER SUPPORT
            </h1>
          </div>
          <div className="mx-[4%] flex flex-col justify-center items-center">
            <div className="flex items-center">
              <h1 className="text-[4vw] text-darkblue font-[600] font-poppins pr-[.5vw] ">
                <CountUp end={95} enableScrollSpy scrollSpyOnce={true} />
              </h1>
              <FaPercentage className="text-primary text-[3vw] " />
            </div>
            <h1 className="text-[1.2vw] text-text2 font-[600] font-poppins">
              TIMELY SERVICE
            </h1>
          </div>
          <div className="mx-[4%] flex flex-col justify-center items-center">
            <div className="flex items-center">
              <h1 className="text-[4vw] text-darkblue font-[600] font-poppins pr-[.5vw] ">
                <CountUp end={97} enableScrollSpy scrollSpyOnce={true} />
              </h1>
              <FaPercentage className="text-primary text-[3vw] " />
            </div>
            <h1 className="text-[1.2vw] text-text2 font-[600] font-poppins">
              On-Demand Assistance
            </h1>
          </div>
        </div>

        <div className="absolute top-[10%] left-0 right-0 bottom-0">
          <img
            src={require("../../assets/child.jpeg")}
            className="w-[100%] h-[15vw] scale-100"
            alt="child"
          />
        </div>
        <div className="absolute inset-0 radial-gradient top-0 left-0 right-0 bottom-0 "></div>
      </div>
      <div className="flex flex-col justify-center  items-center mt-[5%]">
        <h1 className="md:text-[2.5vw] sm:text-[12px] text-primary font-[700] font-poppins">
          Our Inspiring{" "}
          <span className="text-black font-[700] font-poppins">
            Mission, Vision and Values
          </span>
        </h1>
        <p className="md:text-[1.2vw] sm:text-[8px] font-[300] font-poppins w-[60vw] text-center">
          Revolutionizing healthcare with compassion and innovation
        </p>
      </div>
      <div className="flex items-center justify-center my-[2%]">
        <div className="bg-white hover:bg-primary group md:w-[25%] md:h-[35vw] sm:w-[30%] sm:h-[55vw] py-[5%] rounded-[2vw]  mx-[2%]">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[12vw] h-[12vw] rounded-full bg-white flex justify-center items-center">
              <img
                src={require("../../assets/mission.png")}
                alt="doctor"
                className="w-[10vw] h-[10vw] "
              />
            </div>
            <h1 className="md:text-[1.5vw] sm:text-[8px] text-black group-hover:text-white font-[700] font-poppins pt-[7%]">
              Our Mission
            </h1>
          </div>
          <p className="md:text-[1vw] sm:text-[7px] pt-[5%] mt-[2%] font-[400]  group-hover:text-white  font-poppins text-text1 w-[90%] pl-[5%] text-left mx-auto">
            To provide proactive, empathetic, and expert healthcare advocacy and
            coordination services, connecting individuals and families with the
            right care, at the right time, and fostering a community of care and
            support.
          </p>
        </div>
        <div className="bg-white hover:bg-darkblue group md:w-[25%] md:h-[35vw] sm:w-[30%] sm:h-[55vw] py-[5%] rounded-[2vw]  mx-[2%]">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[12vw] h-[12vw] rounded-full bg-white flex justify-center items-center">
              <img
                src={require("../../assets/vision.png")}
                alt="doctor"
                className="w-[9vw] h-[9vw] "
              />
            </div>
            <h1 className="sm:text-[8px] md:text-[1.5vw] text-black  group-hover:text-white  font-[700] font-poppins pt-[5%]">
              Our Vision
            </h1>
          </div>
          <p className="sm:text-[7px] md:text-[1vw] pt-[5%] mt-[2%] font-[400] font-poppins text-text1  group-hover:text-white  w-[90%] pl-[5%] text-left mx-auto">
            Transforming the healthcare experience through innovative advancing medical care
            and coordination services.
          </p>
        </div>
        <div className="bg-white hover:bg-primary group md:w-[25%] md:h-[35vw] sm:w-[30%] sm:h-[55vw] py-[3%] rounded-[2vw] mx-[2%]">
          <div className="flex flex-col items-center justify-center mt-[2%]">
            <div className="w-[12vw] h-[12vw] rounded-full bg-white flex justify-center items-center">
              <img
                src={require("../../assets/values.png")}
                alt="doctor"
                className="w-[50%] h-[60%] "
              />
            </div>
            <h1 className="md:text-[1.5vw] sm:text-[8px] text-black group-hover:text-white  font-[700] font-poppins pt-[8%]">
              Our Values
            </h1>
          </div>
          <p className="md:text-[1vw] sm:text-[7px] pt-[5%] mt-[2%] font-[400] font-poppins  group-hover:text-white  text-text1 w-[90%] pl-[5%] text-left mx-auto">
            At WeStayClose, we are committed to compassion,
            excellence, and innovation. We prioritize patient-centered care,
            ensuring every individual receives personalized attention.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ourfocus;
