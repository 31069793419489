import React from 'react'

const Signin = () => {
  return (
    <div className=''>
      <div className='absolute top-0 left-0 right-[40%] bottom-0'>
        <img src={require('../../assets/bg.jpeg')} className='w-[100vw] h-[100vh] object-cover scale-100' alt='bg'/>
      </div>
      <div className="absolute inset-0 bg-primary opacity-[.5]"></div>
      <div className='absolute z-10 inset-1 top-0 left-[50%] rounded-l-[3vw] bg-white'>
        <div className='flex items-center px-[25%] '>
          <img src={require('../../assets/logo1.png')} className='w-[8vw] h-[8vw]' alt='logo1'/>
          <img src={require('../../assets/logo2.png')} className='w-[16vw] h-[4vw] ' alt='logo2'/>
        </div>
      </div>
    </div>
  )
}

export default Signin
