import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Whatwedo = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Slide transition speed
    slidesToShow: 3, // Show 2 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Enable autoplay
    responsive: [
      {
        breakpoint: 1024, // For laptops and below
        settings: {
          slidesToShow: 2, // Show 2 slides on laptop-sized screens
        },
      },
      
      {
        breakpoint: 720, // For smaller mobile screens
        settings: {
          slidesToShow: 1, // Optional: 1 slide for small screens
        },
      },
     
    ],
    autoplaySpeed: 3000, // Autoplay interval (2 seconds)
    pauseOnHover: false, // Do not pause when hovered
    appendDots: (dots) => (
      <ul className="pl-[1%]"> {} </ul> // Custom tailwind for the dots container
    ),
  };

  const First = () => {
    return (
      <div className="flex-none w-[75%]  p-4 bg-white border rounded-[1vw] mx-[1vw] ">
        <div className="bg-bgicons sm:p-[3px] md:p-[1vw] md:w-[3vw] sm:w-[20px] md:h-[3vw] sm:h-[20px] flex justify-center items-center rounded-full">
          <img
            src={require("../../assets/icons/emergencycare.png")}
            alt="emergency care"
            className="scale-[125%]"
          />
        </div>
        <h1 className=" font-poppins font-[700] md:text-[1.2vw] sm:text-[12px] mt-[1%]">
          EMERGENCY CARE
        </h1>
        <p className=" font-poppins md:text-[1vw] sm:text-[8px] font-[300] w-[100%] md:w-[18vw] mt-[1%]">
          Urgent care when you need it most. Quick, expert assistance in
          emergencies
        </p>
      </div>
    );
  };
  const Second = () => {
    return (
      <div className="flex-none w-[75%] p-4 bg-white border rounded-[1vw] mx-[1vw] ">
        <div className="bg-bgicons sm:p-[3px] md:p-[1vw] md:w-[3vw] sm:w-[20px] md:h-[3vw] sm:h-[20px] flex justify-center items-center rounded-full">
          <img
            src={require("../../assets/icons/clock.png")}
            alt="clock"
            className="scale-[125%]"
          />
        </div>
        <h1 className=" font-poppins font-[700] md:text-[1.2vw] sm:text-[12px] mt-[1%]">
          TIMELY SERVICE
        </h1>
        <p className=" font-poppins md:text-[1vw] sm:text-[8px] font-[300]w-[100%] md:w-[18vw] mt-[1%]">
          Expect timely service from us. Our prompt assistance guarantees peace
          of mind, every time
        </p>
      </div>
    );
  };
  const Third = () => {
    return (
      <div className="flex-none w-[75%] p-4 bg-white border rounded-[1vw] mx-[1vw] ">
        <div className="bg-bgicons sm:p-[3px] md:p-[1vw] md:w-[3vw] sm:w-[20px] md:h-[3vw] sm:h-[20px] flex justify-center items-center rounded-full">
          <img
            src={require("../../assets/icons/resourcing.png")}
            alt="resoucrce"
            className="scale-[125%]"
          />
        </div>
        <h1 className=" font-poppins font-[700] md:text-[1.2vw] sm:text-[8px] mt-[1%]">
          RESOURCING
        </h1>
        <p className=" font-poppins md:text-[1vw] sm:text-[8px] font-[300] w-[100%] md:w-[20vw] mt-[1%]">
          To ensure rapid contact with essential personel during emergencies with limited availability
        </p>
      </div>
    );
  };
  const Fourth = () => {
    return (
      <div className="flex-none w-[75%] h-[45%] p-4 bg-white border rounded-[1vw] mx-[1vw] ">
        <div className="bg-bgicons sm:p-[3px] md:p-[1vw] md:w-[3vw] md:h-[3vw] sm:w-[20px] sm:h-[20px] flex justify-center items-center rounded-full">
          <img
            src={require("../../assets/icons/phone.png")}
            alt="emergency care"
            className="md:scale-[150%] sm:scale-125"
          />
        </div>
        <h1 className=" font-poppins font-[700] md:md:text-[1.2vw] sm:text-[12px]  mt-[1%]">
          CUSTOMER SUPPORT
        </h1>
        <p className=" font-poppins md:text-[1vw] sm:text-[8px] font-[300] w-[100%] md:w-[18vw] mt-[1%]">
          Continuous customer support available to address your inquiries and
          concerns promptly.
        </p>
      </div>
    );
  };
  return (
    <section
      id="Service"
      data-aos="zoom-in"
       data-aos-duration="300"
      className="bg-secondary relative bottom-0 md:h-[40vw] sm:h-[45vw]  mt-[5%]"
    >
      <div className=" bg-gradient-to-r from-primary2 to-secondary1 lg:h-[33vw] sm:h-[45vw] rounded-tr-[15vw] ">
        <div className="flex ">
          <img
            src={require("../../assets/logo.png")}
            alt="logo"
            className="sm:w-[20vw] sm:h-[15vw] lg:w-[20vw] lg:h-[15vw] opacity-[10%] mt-[1%] border-4 scale-125"
          />
          <div className="mt-[3%]">
            <h1 className="text-[3vw] font-[700] font-poppins text-black1">
              What We Do
            </h1>
            <h1 className="text-[3vw] font-[300] font-poppins text-black1">
              For Your Business
            </h1>
          </div>
        </div>
        <div className="w-[100%] relative z-30">
          <Slider {...settings} className="md:pl-[2%] sm:pl-[7%] ">
            <div
              className={`w-[30%] sm:w-[75%]  transition-opacity duration-500 ease-out `}
            >
              <First />
            </div>
            <div
              className={`w-[30%]  transition-opacity duration-500 ease-out `}
            >
              <Second />
            </div>
            <div
              className={`w-[30%] transition-opacity duration-500 ease-out `}
            >
              <Third />
            </div>
            <div
              className={`w-[30%]  transition-opacity duration-500 ease-out `}
            >
              <Fourth />
            </div>
          </Slider>
        </div>
        <div className="w-[28vw] h-[28vw] border-2 border-dotted border-elipse rounded-full absolute top-[30%] right-[-10%] z-20"></div>
      </div>
    </section>
  );
};

export default Whatwedo;
