import React from "react";
import { FaStar } from "react-icons/fa"; // Import star icon from react-icons

const StarRating = ({ rating }) => {
  const totalStars = 5;
  return (
    <div className="flex space-x-0">
      {Array.from({ length: totalStars }, (v, i) => (
        <div key={i} className="relative">
          {/* Empty star */}
          <FaStar className="text-gray-300" size={'1.5vw'} />
          
          {/* Filled star overlay with width based on rating */}
          {i < rating && (
            <div
              className="absolute top-0 left-0 h-full overflow-hidden "
              style={{
                width: `${Math.min(1, rating - i) * 100}%`,
              }}
            >
              <FaStar className="text-star" size={'1.5vw'} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StarRating;
