import React from 'react'

const Thiredscreen = () => {
  return (
    <div className="bg-secondary flex h-[50vw] justify-between pl-[3%] mt-[1%]"> {/*third screen */}
        <div className="flex flex-col items-start justify-center ml-[1%]">
          <div className="bg-primary1 rounded-lg  px-4">
            <h1 className=" text-primary md:text-[1.2vw] sm:text-[8px] font-poppins font-[600]">
              DON'T WORRY
            </h1>
          </div>
          <div className="flex flex-col items-start">
            <h2 className="text-[3.5vw] font-[700] font-poppins text-primary ">
              Love And Care ,
            </h2>
            <h2 className="text-[3.5vw] font-[700] font-poppins text-darkblue ">
              No Matter The Distance
            </h2>
          </div>
          <p className=" mb-3 text-text1 whitespace-pre-line w-[40vw] text-center mt-5 sm:text-[8px] md:text-[1.3vw]">
            No matter how far you are, your love and care can still make a
            difference. Stay connected and ensure your loved ones' safety and
            happiness from anywhere.
          </p>
          <div className="mt-[2%] ">
            <button
              type="button"
              className="  w-[12vw] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg text-sm sm:text-[8px] md:py-[8%] text-center me-2 mb-2"
            >
              <p className='text-white text-[1.5vw]'>Get Service</p>
            </button>
          </div>
        </div>
        <div className="relative h-[35vw] mr-[5%] mt-[2%]">
          <div className="w-[40vw] h-[35vw] rounded-tl-[15vw] overflow-hidden rounded-bl-[2vw]">
          <img
            src={require("../../assets/electric.jpg")}
            className="w-full h-full object-cover object-center scale-[200%]"
            alt="trust hand"
          />
          </div>
          <div className="absolute inset-0 bg-white opacity-45 rounded-tl-[15vw] "></div>
          <div  className="absolute inset-0 lg:top-[24%] lg:left-[9%] z-10 w-[24vw] h-[28vw] sm:top-[40%] sm:left-[10%] border-8 rounded-t-full overflow-hidden " >
          <img
            src={require("../../assets/electric.jpg")}
            alt="trust hand"
            className=" w-full h-full object-cover scale-[175%]"
          />
          </div>
        </div>
      </div>
  )
}

export default Thiredscreen
