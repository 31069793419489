import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
// import Navbar from "../../component/Navbar/navbar";
const Contact = () => {
  const [selected, setSelected] = useState(null);
  return (
    <>
   {/* <Navbar/> */}
    <div className="bg-white md:flex  m-2">
      <div className="bg-primary sm:h-[70vh] md:w-[45vw] lg:w-[50%] md:h-[95vh] rounded-xl pl-[2%] relative overflow-hidden">
        <h1 className="text-white font-[600] font-poppins md:text-[3.5vw] lg:text-[2.5vw] mt-[4%]">
          Contact Information
        </h1>
        <p className="text-white font-[400] font-poppins md:text-[2vw] lg:text-[1.5vw]">
          Say something to start a live chat!
        </p>
        <div className="flex items-center mt-[6%]">
          <FaRegClock className="lg:w-[1.5vw] md:w-[3vw]" color="white" />
          <p className="text-white pl-[2%] font-[400] font-poppins md:text-[2vw] lg:text-[1.2vw]">
            Monday to Saturday
          </p>
        </div>
        <div className="flex items-center mt-[6%]">
          <FaPhoneVolume className="lg:w-[1.5vw] md:w-[3vw]" color="white" />
          <p className="text-white pl-[2%] font-[400] font-poppins md:text-[2vw] lg:text-[1.2vw]">
            +91 97890 86627
          </p>
        </div>
        <div className="flex items-center mt-[6%]">
          <MdEmail className="lg:w-[1.5vw] md:w-[3vw]" color="white" />
          <p className="text-white pl-[2%] font-[400] font-poppins md:text-[2vw] lg:text-[1.2vw]">
            info@westayclose.in
          </p>
        </div>
        <div className="flex items-start mt-[6%] absolute z-10">
          <HiLocationMarker className="lg:w-[1.5vw] md:w-[3vw]" color="white" />
          <p className="text-white w-[75%] pl-[2%] font-[400] font-poppins md:text-[2vw] lg:text-[1.2vw]">
            Greeta Towers, Industrial Estate, Perungudi, OMR Phase-1 Chennai,
            Tamil Nadu 600 096. India.
             Registered Office: Livmo Pvt. Ltd., No.
            36/3, Otheri Road, Swamy Nagar, Otteri, Vellore - 632 002.
          </p>
        </div>
        <div className="absolute bottom-4 flex justify-between px-5 w-[34%]">
          <div className=" flex justify-center items-center   sm:pl-[18%] md:pl-[2%]">
            <FaFacebook style={{ color: "#0044cd" }} className="lg:w-[2.3vw] md:w-[3vw] sm:w-[18px] sm:h-[18px] md:h-[3vw]" />
          </div>
          <div className=" flex justify-center items-center  sm:pl-[18%] md:pl-[2%]">
            <FaInstagram style={{ color: "#0044cd" }} className="lg:w-[2.3vw] md:w-[4vw]  sm:w-[18px] sm:h-[18px] md:h-[3vw]" />
          </div>
          <div className=" flex justify-center items-center  sm:pl-[18%] md:pl-[2%]">
            <FaLinkedin style={{ color: "#0044cd" }} className="lg:w-[2.3vw] md:w-[4vw]  sm:w-[18px] sm:h-[18px] md:h-[3vw]" />
          </div>
          <div className=" flex justify-center items-center  sm:pl-[18%] md:pl-[2%]">
            <AiFillYoutube style={{ color: "#0044cd" }} className="lg:w-[2.3vw] md:w-[4vw]  sm:w-[18px] sm:h-[18px] md:h-[3vw]" />
          </div>
        </div>
        <div className="w-[10vw] h-[10vw] rounded-full bg-darkblueshade1 absolute bottom-[18%] right-[16%]" />
        <div className="w-[20vw] h-[20vw] rounded-full bg-darkblueshade2 absolute bottom-[-10%] right-[-10%]" />
      </div>
      <div className="md:w-[50%] sm:w-[100%]">
        <form className="mt-[6%]">
          <div className="flex justify-evenly">
            <div className="w-[45%] ">
              <h1 className="text-cardtex font-[500] font-poppins sm:text-[12px] md:text-[1.3vw]">First Name</h1>
              <input
                type="text"
                className=" border-b-2 w-[85%] focus:outline-none md:text-[1.8vw] text-text1 lg:text-[1.5vw] focus:border-black py-[2%]"
              />
            </div>
            <div className="w-[45%]">
              <h1 className="text-cardtex font-[500] font-poppins sm:text-[12px] md:text-[1.3vw]">Last Name</h1>
              <input
                type="text"
                className=" border-b-2 w-[85%] focus:outline-none  text-text1 lg:text-[1.5vw] focus:border-black py-[2%]"
              />
            </div>
          </div>
          <div className="flex justify-evenly mt-[4%]">
            <div className="w-[45%] ">
              <h1 className="text-cardtex font-[500] font-poppins sm:text-[12px] md:text-[1.3vw]">Email</h1>
              <input
                type="text"
                className=" border-b-2 w-[85%] focus:outline-none text-text1 lg:text-[1.5vw] focus:border-black py-[2%]"
              />
            </div>
            <div className="w-[45%]">
              <h1 className="text-cardtex font-[500] font-poppins sm:text-[12px] md:text-[1.3vw]">Phone Number</h1>
              <input
                type="text"
                className=" border-b-2 w-[85%] focus:outline-none   text-text1 lg:text-[1.5vw] focus:border-black py-[2%]"
              />
            </div>
          </div>
          <h1 className="sm:text-[12px] md:text-[1.5vw] text-black font-poppins font-[600] mt-[4%] pl-[4%]">
            Select Subject?
          </h1>
          <div className="flex space-x-4 mt-[2%] pl-[4%]">
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="option"
                value="General Inquiry"
                checked={selected === "General Inquiry"}
                onChange={() => setSelected("General Inquiry")}
                className="form-radio md:h-[1.6vw] md:w-[1.6vw] sm:h-[12px] sm:w-[12px] text-blue-600"
              />
              <span className="lg:text-[1vw] md:text-[1.5vw] font-[400] font-poppins">
                General Inquiry
              </span>
            </label>
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="option"
                value="Know more"
                checked={selected === "Know more"}
                onChange={() => setSelected("Know more")}
                className="form-radio md:h-[1.6vw] md:w-[1.6vw] sm:h-[12px] sm:w-[12px] text-blue-600"
              />
              <span className="lg:text-[1vw] md:text-[1.5vw] font-[400] font-poppins">
                Know more
              </span>
            </label>
          </div>
          <h1 className="lg:text-[1.2vw] md:text-[2vw] font-poppins font-[400] mt-[4%] pl-[4%]">Message</h1>
          <div className="mt-[2%] pl-[4%]">
          <input type="text" className="border-b-2 focus:border-black focus:outline-none w-[90%]" placeholder="Write Your Message" />
          </div>
          <div className="mt-[4%] flex justify-end pr-[5%]">
            <button
              type="button"
              className="  w-[25%] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg sm:py-[2%]  md:py-[1vw]  me-2 mb-2"
            >
              <p className="text-white md:text-[1.3vw] sm:text-[12px] font-poppins">
               Send Message
              </p>
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default Contact;
