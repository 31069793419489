import React from "react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Support = () => {
  const navigate = useNavigate()
  return (
    <section id="Contact"  className="bg-secondary mt-[2%] ">
      <div className="flex flex-col justify-center  items-center">
        <h1 className="text-[2.5vw] font-[700] font-poppins">
          Reach Our <span className="text-primary">Help Desk</span> For Support
        </h1>
        <p className="text-[1.2vw] text-text1 font-[300] font-poppins w-[35vw] text-center">
          Questions? Need assistance? Our dedicated support team is here to help
          you every step of the way:
        </p>
        <div className="mt-[2%] ">
          <button
          onClick={()=>{navigate('/contact')}}
            type="button"
            className="  w-[12vw] bg-gradient-to-r from-primary via-primary to-gradient2 font-medium rounded-lg  py-[1.3vw] text-center me-2 mb-2"
          >
            <div className="flex items-center justify-center">
              <p className="text-white text-[1.3vw] font-poppins">Contact Us</p>
              <FaRegArrowAltCircleRight className="w-[3vw] h-[1.5vw] text-white" />
            </div>
          </button>
        </div>
      </div>
      
    </section>
  );
};

export default Support;
