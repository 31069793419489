import React from "react";
import Aboutus from "../../component/About us/Aboutus";
import Headerinfo from "../../component/HeaderInfo/Headerinfo";
import Hero from "../../component/Hero screen/Hero";
import Navbar from "../../component/Navbar/navbar";
import Whatwedo from "../../component/What do/Whatwedo";
import Platform from "../../component/Platform work/Platform";
import Ourfocus from "../../component/Our focus/Ourfocus";
import Testimony from "../../component/testimony/Testimony";
import SocialMedia from "../../component/Social media/SocialMedia";
import Support from "../../component/Support/Support";
import Footer from "../../component/Footer/Footer";
import Floating from "../../component/Floating button/Floating";
import Plans from "../../component/Plan/Plans";
import Sponser from "../../component/Support/Sponser";

const Homepage = () => {
  return (
    <main className="bg-secondary relative ">
      <Headerinfo />
      <Navbar />
      <div className="overflow-hidden">
        <Hero />
        <Aboutus />
        <Whatwedo />
        <Platform />
        <Ourfocus />
        <Plans />
        <Testimony />
        <SocialMedia />
        <Support />
        {/* <Sponser /> */}
        <Floating />
        <Footer />
      </div>
    </main>
  );
};

export default Homepage;
